import React, { ReactNode, createContext, useContext, useState } from 'react';
import AuthenticationService from './authentication-service';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  token: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode; 
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => localStorage.getItem("isAuthenticated") === "true");
  const [token, setToken] = useState(() => localStorage.getItem("token")||"");

  const login = async (username: string, password: string) => {
    const result = await AuthenticationService.logon(username, password);

    if (result)
    {
    setIsAuthenticated(AuthenticationService.isAuthenticated);
    setToken(result.data.attributes.token);
    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("token", result.data.attributes.token);
    }
  };

  const logout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
