import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../services/AuthContext';
import BulletinService from '../services/bulletin-service';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Resultat from '../models/resultat';
import { Box } from '@mui/material';
import Session from '../models/session';
import resultatAttribute from '../models/resultat-attribute';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 200 },
  {field: 'identifiantEchantillon', headerName: 'Echantillon', width: 200},
  {field: 'codeParametre', headerName: 'Code', width: 100},
  {
    field: 'resultat',
    headerName: 'Résultat',
    width: 300,
    editable: true,
  },
  {
    field: 'methode',
    headerName: 'Méthode',
    width: 100,
    editable: false,
  }
];

interface RowData {
    id: string;
    identifiantEchantillon: string;
    codeParametre: string;
    resultat: string;
    methode: string
  }

const Detail = () => {
    const { id } = useParams(); // Utilisation de useParams pour accéder à l'id
    const auth = useAuth();

    const [resultats, setResultats] = useState<RowData[]>([]);
    
    useEffect(() => {
        if (!id) return;
        console.info(`detail URL = ${Session.Url}`);
        const bulletinPromise = BulletinService.getResultats(auth.token, id.toString());
        bulletinPromise.then(p => {
          if (auth.isAuthenticated) {
            const transformedData = p.data.map((item) => ({
              id: item.id,
              identifiantEchantillon: item.attributes.identifiantEchantillon,
              codeParametre: item.attributes.codeParametre,
              resultat: item.attributes.resultat,
              methode: item.attributes.methode
            }));
    
            setResultats(transformedData);
            
            for(let i = 0; i < transformedData.length; i++)
            {
              var resultat = new resultatAttribute(
                transformedData[i].identifiantEchantillon, 
                transformedData[i].codeParametre, 
                transformedData[i].resultat, 
                transformedData[i].methode);
              BulletinService.resultDb(resultat);
            }
          }
        });
      }, []);
    
    return (
        <Box sx={{ height: 800, width: '100%' }}>
        <DataGrid
          rows={resultats}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
        />
      </Box>
      );
};

export default Detail;
