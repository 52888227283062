import { AuthProvider } from './services/AuthContext';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/login';
import Bulletin from './components/bulletin-grid';
import PageNotFound from './pages/page-not-found';
import Detail from './components/detail';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/bulletin" element={<ProtectedRoute><Bulletin /></ProtectedRoute>} />
          <Route path="/detail/:id" element={<ProtectedRoute><Detail /></ProtectedRoute>} />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route element={<PageNotFound />} />
        </Routes>
      </Router>    
    </AuthProvider>
  );
}

export default App;
