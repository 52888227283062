import Logon from '../models/logon';
import User from '../models/user';
import Session from '../models/session';

export default class AuthenticationService  {
  static isAuthenticated: boolean = false;
  static token: string;
  static user: User;

  static logon(user: string, pwd: string): Promise<User> {
    var logon = new Logon(user, pwd);

    return fetch(`${Session.Url}/api/logon`, {
      method: 'POST',
      body: JSON.stringify(logon),
      redirect: 'follow',
      headers:
      {
        'Content-Type': 'application/json',
        'Static-Token': Session.StaticToken
      }
    })
      .then(response => {
        console.info("auth = true");
        this.isAuthenticated = true;
        return response.json();
      })
      .catch(error => this.handleError(error));
  }

  /*static getToken(): Promise<User> {
    //process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"; // À utiliser temporairement pour contourner le problème

    var logon = new Logon(Session.User, Session.Pwd);        
    
    return fetch(`${Session.Url}/api/logon`, {
        method: 'POST',
        body: JSON.stringify(logon),
        redirect: 'follow', 
        headers: 
        { 'Content-Type': 'application/json',
          'Static-Token': Session.StaticToken
        }
      })
      .then(response => response.json())
      .catch(error => this.handleError(error));
  }*/

  static handleError(error: Error): void {
    console.error("AUTHENTIFICATION");
    console.error(error);
  }
}

