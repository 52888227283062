const formatDate = (date: Date = new Date()) : string => 
{
    const dt = new Date(date);
    
    const day = dt.getUTCDate().toString().padStart(2, '0');
    const month = (dt.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dt.getUTCFullYear();

    return `${day}/${month}/${year}`;
}

export default formatDate;