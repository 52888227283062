import Session from '../models/session';
import Bulletin from '../models/bulletin';
import Resultat from '../models/resultat';
import bulletinAttribute from '../models/bulletin-attribute';
import resultatAttribute from '../models/resultat-attribute';

export default class BulletinService {

    static getBulletins(token: string): Promise<Bulletin>
    {
        var req = `${Session.Url}/api/bulletins?$filter=DateArrivee ge 2023-04-01 and DateArrivee le 2023-04-24&$orderby=DateArrivee`;
        //var req = `${Session.Url}/api/bulletins`;

        var retour = fetch(req, {
            headers: {
                "Static-Token": Session.StaticToken,
                "Authorization": `Bearer ${token}`,
            },
            redirect:'follow'
        });

        console.info(`Appel bulletins : ${req}`);
        //retour.then(response => console.info(response));

        return retour
            .then(response => response.json())
            .catch(error => this.handleError(error));
    }
    
    static getResultats(token: string, id:string): Promise<Resultat>
    {
        var req = `${Session.Url}/api/bulletins/${id}/resultats`;

        var retour = fetch(req, {
            headers: {
                "Static-Token": Session.StaticToken,
                "Authorization": `Bearer ${token}`,
            },
            redirect:'follow'
        });

        //retour.then(response => console.info(response));

        return retour
            .then(response => response.json())
            .catch(error => this.handleError(error));
    }

   static isEmpty(data: Object): boolean {
        return Object.keys(data).length === 0;
      }
    
    static handleError(error: Error): void {
        console.error(error);
      }

      static resultDb(resultat: resultatAttribute)
      {
        console.info(resultat.identifiantEchantillon);
          let open = indexedDB.open("aresweb");
  
          open.onblocked = function() {console.info("bloquée")};
          
          // La base n'existe pas
          open.onupgradeneeded = function() 
          {
            console.info("base inexistante");
          };
  
          open.onerror = function()
          {
              console.error('Erreur', open.error);
          }
  
          open.onsuccess = function() 
          {
            console.info("succès");
            /*
              let db = open.result;
           
              db.createObjectStore("resultat");

              let transaction = db.transaction("resultat", 'readwrite');

              let resultats = transaction.objectStore("resultat");

              let request = resultats.add(resultat);

              request.onsuccess = function() 
              {
                console.info("succès");
              }

              request.onerror = function() 
              {
                console.error("erreur", request.error);
              }*/
          }
          
      }
}
