export default class resultatAttribute
{
    identifiantEchantillon: string;
    codeParametre: string;
    resultat: string;
    methode: string;

    constructor(
        identifiantEchantillon: string,
        codeParametre: string,
        resultat: string,
        methode: string
    )
    {
        this.identifiantEchantillon = identifiantEchantillon;
        this.codeParametre = codeParametre;
        this.resultat = resultat;
        this.methode = methode;
    }
}
