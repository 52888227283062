import { FunctionComponent, useEffect, useState } from 'react';
import BulletinService from '../services/bulletin-service';
import formatDate from '../helpers/format-date';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import { useAuth } from '../services/AuthContext';
import { useNavigate } from 'react-router-dom';

/*const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 200 },
  {field: 'reference', headerName: 'Référence', width: 200},
  {
    field: 'date',
    headerName: 'Date',
    width: 120,
    editable: true,
  },
  {
    field: 'nature',
    headerName: 'Nature',
    width: 200,
    editable: false,
  },
  {
    field: 'lien',
    headerName: 'Action',
    width: 200,
  }
];*/

interface RowData {
  id: string;
  reference: string;
  date: string;
  nature: string,
  lien: string
}

const BulletinGrid: FunctionComponent = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [bulletins, setBulletins] = useState<RowData[]>([]);

  useEffect(() => {
    console.info(`BulletinGrid, auth = ${auth.isAuthenticated}`);
    const bulletinPromise = BulletinService.getBulletins(auth.token);
    bulletinPromise.then(p => {
      if (auth.isAuthenticated) {
        console.info(JSON.stringify(p.data));
        const transformedData = p.data.map((item) => ({
          id: item.id,
          reference: item.attributes.identifiantBulletin,
          date: formatDate(item.attributes.dateArrivee),
          nature: item.attributes.designationNature,
          lien: item.attributes.lien
        }));

        setBulletins(transformedData);
      }
    });
  }, []);

  // Fonction pour générer les colonnes avec la navigation intégrée
  const getColumns = (): GridColDef[] => [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'reference', headerName: 'Référence', width: 200 },
    { field: 'date', headerName: 'Date', width: 120 },
    { field: 'nature', headerName: 'Nature', width: 200 },
    {
      field: 'lien',
      headerName: 'Lien',
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Empêchez la navigation par défaut
              navigate(`/detail/${cellValues.id}`); // Utilisez navigate ici
            }}
          >
            Voir détail
          </Link>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 800, width: '100%' }}>
      <DataGrid
        rows={bulletins}
        columns={getColumns()}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7]}
      /*checkboxSelection
        disableRowSelectionOnClick*/
      />
    </Box>
  );
}

export default BulletinGrid;