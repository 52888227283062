import { Navigate } from "react-router-dom";
import { useAuth } from "./services/AuthContext";

interface ProtectedRouteProps {
    children: React.ReactNode; // Typage pour les enfants du composant
  }
  
  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const auth = useAuth();
    
    console.info(`ProtectedRoute, auth = ${auth.isAuthenticated}`);
    if (!auth.isAuthenticated) {
      // Redirige vers la page de connexion si non authentifié
      return <Navigate to="/login" />;
    }
  
    // Rend les enfants si authentifié
    return <>{children}</>;
  };
  
  export default ProtectedRoute;